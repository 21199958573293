import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageKlondikeBarSandwich = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/Wright-Brand-Recipe-Klondike-Sandwich-Lifestyle-1367×737.jpg';

const RecipePage = () => (
    <Layout metaTitle="Klondike® Bar Bacon Sandwich"
        metaDescription="Who doesn't love bacon on an ice cream sandwich? Try weaving our thick-cut bacon with an ice cream sandwich in between for a sweet and salty combination."
        metaKeywords=""
    >
        <meta property="og:image" content={imageKlondikeBarSandwich} />
        <link rel="image_src" href={imageKlondikeBarSandwich} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageKlondikeBarSandwich} class='img-responsive' alt='The Wright Brand Klondike Sandwich' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>The Wright<sup>&reg;</sup> Brand Klondike<sup>&reg;</sup> Sandwich</h1>

                            <div class="recipe-content">
                                <p >A savory twist to a childhood favorite!</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20-25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>1</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom">Strips</span> of Wright® Brand Hickory Smoked Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Klondike®</span> bar
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat oven to 350F.

                </p>
                            <p>
                                2. Make bacon weave by laying three strips of Wright® Brand bacon horizontally together. Then fold the center strip in half to the right.  
                                Set another strip of bacon vertically in the middle where the fold is. Fold the folded bacon back in place and now fold the top and bottom 
                                bacon in half to the right. Add your fifth vertical strip on the left side and fold over top and bottom bacon over it. Fold top and bottom 
                                bacon to the left side and add your last slice on the right side and fold back the top and bottom slices over.

                </p>
                            <p>
                                3. Repeat for top layer and place both weaves on a sheet pan. Place a cooling rack upside down on top of the bacon so it stays flat and bake 
                                for 20-25 minutes until crispy.

                </p>
                            <p>
                                4. Allow to rest on paper towels and then sandwich a Klondike® bar between the two bacon weaves and serve immediately.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
